<template>
    <div :class="[this.$vuetify.theme.dark? 'dark-bg' : '', 'mb-3 card']">
        <div>
            <v-container fluid>

                <p><b>{{ $t('reports.lang_downloadSalesReport') }}:</b></p>
                <p>- {{ $t('reports.lang_pleaseSelectReportAndPressDownload') }}<br>
                   - {{ $t('reports.lang_dependingOnNumberOfSales') }}</p>

                <v-layout>
                    <v-flex  mx-2 md6 sm12>
                        <v-text-field dense :label="$t('generic.lang_from')" outlined type="date" v-model="start"/>
                    </v-flex>

                    <v-flex mx-2 md6 sm12>
                        <v-text-field dense :label="$t('generic.lang_till')" outlined type="date" v-model="end"/>
                    </v-flex>
                </v-layout>

                <v-layout>
                    <v-flex md6 mr-2 ml-2>
                        <v-select outlined
                                  :items="saleReportsSelect"
                                  item-text="name"
                                  item-value="id"
                                  v-model="saleReports"
                                  :label="$t('reports.saleReport')"
                        ></v-select>
                    </v-flex>

                    <v-flex md6 mr-2 ml-2>
                        <v-btn text color="error" @click="downloadReport">{{ $t('generic.lang_download') }}</v-btn>
                    </v-flex>
                </v-layout>

            </v-container>
        </div>
        <div id="onScreenKeyboardDiv">
            <vue-touch-keyboard class="internalWidth" id="onScreenKeyboard" :options="touchKeyboard.options" :defaultKeySet="touchKeyboard.keySet" v-if="touchKeyboard.visible" :layout="touchKeyboard.layout" :cancel="hideTouchKeyboard"
                                :accept="hideTouchKeyboard" :input="touchKeyboard.input"/>
        </div>
    </div>
</template>



<script>
    import {ENDPOINTS} from '../../config'
    import {Events} from "../../plugins/events";
    import {mapState} from "vuex";
    import mixin from "../../mixins/KeyboardMixIns";
    import moment from "moment";

    export default {
        components: {
        },
        mixins: [mixin],
        data() {
            return {
                ENDPOINTS,
                saleReportsSelect: [
                  {
                    name: this.$t('accounting.lang_salesPositionReport'),
                    id:"saleOverview"
                  },
                  {
                    name: this.$t('accounting.lang_salesReport'),
                    id:"saleOverviewTotal"
                  },
                  {
                    name:  this.$t('accounting.lang_itemGroupSalesLast3yrs'),
                    id:"itemGroupSalesLast3yrs"
                    },
                  {
                    name:  this.$t('accounting.lang_voucherGiftcardAnalysis'),
                    id:"voucherGiftCardAnalysis"
                  },
                  {
                    name: this.$t('reports.lang_openOrderTableReport'),
                    id:"openOrderTableReport"
                  },
                  {
                    name: this.$t('reports.lang_providerOverview'),
                    id:"providerOverview"
                  },
                  {
                    name: this.$t('reports.lang_customerDiscounts'),
                    id:"customerDiscounts"
                  },
                  {
                    name: this.$t('reports.lang_customerGroupDiscount'),
                    id:"customerGroupDiscount"
                  },
                  {
                    name: this.$t('reports.lang_debitCustomerItemList'),
                    id:"debitCustomerItemList"
                  },
                ],
                start:null,
                saleReports:"",
                end:null,
            }
        },
        computed: {
            ...mapState([
                'api'
            ])
        },
        methods: {

            getFrom() {
                let startDate = new Date(this.start);
                return moment.unix(startDate.getTime()/1000).startOf("days").unix();
            },

            getTo(){
                let endDate = new Date(this.end);
                return moment.unix(endDate.getTime()/1000).endOf("days").unix();
            },

            downloadReport: function () {
              let apiServerIP = process.env.VUE_APP_API_HOST;
              if(window.location.host != 'localhost' && window.location.host != 'devkasse.3pos.de' && window.location.host != 'kasse.3pos.de'){
                apiServerIP = location.protocol + "//" + window.location.hostname + ":8010/pos";
              }
                window.open(apiServerIP + "/get/reports/sales/" + this.saleReports + "/?&bs=" + this.api.auth.posID + "&bsid=" + this.api.auth.accessString + "&apiToken=" + this.api.auth.apiWebToken+"&from="+this.getFrom()+"&to="+this.getTo(), "_blank");
            },
        },

        mounted() {

        },
    }
</script>